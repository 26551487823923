.modal {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999999;
    width: 100vw;
    background-color: rgba(31, 30, 30, 0.2);
    height: 100vh;
    background-size: cover;
    -webkit-backdrop-filter: blur(2px); /* Use for Safari 9+, Edge 17+ (not a mistake) and iOS Safari 9.2+ */
    backdrop-filter: blur(2px); /* Supported in Chrome 76 */
}

.content {
    position: absolute;
    display: flex;
    /*justify-content: center;*/
    flex-direction: column;
    align-items: center;
    height: 75%;
    width: 925px;
    max-width: 925px;
    min-width: 925px;
    /*border: 1px solid red;*/
    background: #FFFFFF;
    border-radius: 5px;
    padding: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.content_content {
    width: 100%;
    height: 100%;
    margin-top: 15px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
}

.show_hidden_brn {
    /*border: 1px solid red;*/
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 570px;
    min-width: 570px;
    width: 570px;
    margin: 0 auto;
}


.show_hidden_brn button {
    background-color: #EAEAEA;
}

.header {
    margin-top: 30px;
}

.header h3 {
    font-family: 'Russo One', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    color: #1F1E1E;
}

.header h2 {
    font-family: 'Russo One', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 43px;
    color: #58A2E4;
    text-align: center;
    margin-top: 6px;
}

.exit {
    margin-top: 30px;
}
