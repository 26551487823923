.input_box{
    min-width: 50px;
    border-radius: 3px;
    background: var(--white, #FFF);
    padding: 8px 5px;
    outline: none;
    border: none;
    color: var(--black, #1F1E1E);
    /* body 4 */
    font-family: '.SF NS Rounded',sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
}

.input_box::-webkit-inner-spin-button{
    -webkit-appearance: none !important;
    margin: 0 !important;
}
