.content {
    padding-top: 30px;
    padding-bottom: 80px;
}

.choose_box {
    display: flex;
    align-items: center;
}

.choose_box_item {
    display: flex;
    align-items: center;
    margin-right: 40px;
}

.item_select_assistant {
}

.item_select_assistant  select {
    height: 32px;
    outline: none;
    border: none;
    padding: 0 16px;
    border-radius: 3px;
    width: 100%;
}

.date_wrapper {
    margin-top: 40px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    grid-column: span 3;
}

.choose_box_item div {
    display: flex;
    width: 215px;
    min-width: 215px;
    max-width: 215px;
    padding: 8px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    background: var(--white, #FFF);
    margin-right: 10px;
    color: var(--black, #1F1E1E);
    min-height: 32px;
    /* body 4 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
}

.choose_box_item input {
    display: flex;
    outline: none;
    border: none;
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    padding: 8px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    background: var(--white, #FFF);
    margin-right: 10px;
    color: var(--black, #1F1E1E);
    min-height: 32px;
    /* body 4 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
}

.choose_box_item input::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.btn {
    border-radius: 3px;
    background: var(--blue, #58A2E4);
    display: inline-flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--white, #FFF);
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
    cursor: pointer;
}

.figure {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 6px;
}

.figure_item {
    height: 45px;
}

.table {
    margin-top: 45px;
    display: flex;
    width: 100%;
}

.brigade {
    margin-left: 20px;
}

.brigade_top {
    display: grid;

    grid-column-gap: 6px;
}

.brigade_top_item_top {
    height: 45px;
    display: flex;
    padding: 8px 16px;
    align-items: center;
    /*gap: 10px;*/
    border-radius: 3px;
    background: var(--white, #FFF);
    color: var(--black, #1F1E1E);
    margin-bottom: 6px;
    /* body 4 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
}

.brigade_top_item_top_content {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 6px;
    max-width: 120px;
    width: 100%;
}

.brigade_top_item_top_content_item {
    border-radius: 3px;
    background: var(--Gray-5, #E0E0E0);
    height: 45px;
    /*width: 79px;*/
    padding: 8px 10px;
    align-items: flex-start;
    gap: 10px;
    flex-shrink: 0;
    max-width: 120px;
}

.brigade_top_item_top_content_item_input {
    height: 45px;
    max-width: 120px;
}

.input_table {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    padding: 0 16px;
}

.input_table::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.btn_box {
    min-height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-left: 13px;
}

.brigade_select_box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    margin-top: 20px;
}

.select_box {
    display: grid;
    grid-row-gap: 7px;
    margin-top: 10px;
}

.select_box select {
    height: 32px;
    outline: none;
    border: none;
    padding: 0 16px;
    border-radius: 3px;
    width: 100%;
}

.save_settings{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}
