.content {
    padding-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
    padding-bottom: 80px;
}

.content_left h2, .content_right h2 {
    color: var(--black, #1F1E1E);
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.9px;
}

.content_left_content, .content_right_content {
    margin-top: 22px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 18px;
    grid-row-gap: 7px;
}

.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.name {
    color: var(--black, #1F1E1E);

    /* body 4 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
    padding: 8px 16px;
    /*min-width: 178px;*/
    width: 100%;
    border-radius: 3px;
    background: var(--white, #FFF);
}

.value {
    color: var(--black, #1F1E1E);

    /* body 4 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
    padding: 8px 10px;
    /*min-width: 178px;*/
    width: 50px;
    max-width: 50px;
    min-width: 50px;
    margin-left: 8px;
    border-radius: 3px;
    background: var(--white, #FFF);
}

