.command {
    background: #EAEAEA;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 20px;
}

.commands_box {
    width: calc(100% + 35px);
    position: relative;
}

.show_hidden_brn {
    height: 30px;
    width: 30px;
    position: absolute;
    right: 0px;
    top: -20px;
}

.show_hidden_brn svg {
    transition: .3s all;
}

.show_hidden_brn button:hover {
    background-color: rgba(128, 128, 128, 0.50);
}

.rotate svg {
    rotate: 180deg;
}

.commands_box_header {
    display: flex;
    align-items: center;
}

.empty {
    height: 18vh;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.45);
}

.commands_box_items {
    height: 18vh;
    overflow-x: hidden;
    padding-right: 20px;
    margin-top: 9px;
    transition: .3s all;
}

.show_full {
    height: calc(100vh - 190px);
}

.commands_box_items::-webkit-scrollbar-thumb:vertical {
    background: #EAEAEA;
    border-radius: 5px;
    border: 0.5px solid #1F1E1E;
}

.commands_box_items::-webkit-scrollbar {
    height: 7px;
    width: 18px;
    border-radius: 5px;
    background: #fff;
}

.commands_box_header h3 {
    font-family: '.SF NS Rounded', sans-serif;
    font-style: normal;
    font-weight: 276;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.045em;
    color: #1F1E1E;
}

.command_name {
    min-width: 340px;
    width: 340px;
    max-width: 340px;
}

.total_name, .elements_name, .artistic_name, .points_name ,.rank_name{
    min-width: 183px;
    width: 183px;
    max-width: 183px;
    margin-left: 20px;
    white-space: nowrap;
}

.total_name{
    min-width: 138px;
    width: 138px;
    max-width: 138px;
}

.elements_name{
    min-width: 100px;
    width: 100px;
    max-width: 100px;
}
.points_name{
    min-width: 120px;
    width: 120px;
    max-width: 120px;
}
.rank_name{
    min-width: 100px;
    width: 100px;
    max-width: 100px;
}
