.content {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid rgba(65, 62, 62, .2);
}

.show_users input[type="checkbox"] {
    width: 17px;
    height: 17px;
    border: 0;
    outline: 0;
    text-align: center;
    min-height: 32px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white, #FFF);
    padding: 0;
    position: relative;
    color: var(--black, #1F1E1E);

    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
    cursor: pointer;

}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.show_users p {
    color: #000;
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.9px;
    margin-left: 10px;
    user-select: none;
    cursor: pointer;
}

.show_users {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
}


.table {
    /*max-height: 450px;*/
    /*min-height: 450px;*/
    overflow: auto;
    padding-bottom: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    /*grid-row-gap: 7px;*/
    padding-top: 55px;
}

.btn {
    color: var(--white, #FFF);
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 39px;
    padding: 10px 24px;
    border-radius: 3px;
    background: var(--blue, #58A2E4);

}

.btn_upload {
    color: var(--white, #FFF);
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 39px;
    padding: 10px 15px;
    border-radius: 3px;
    border: 0.2px solid #5b5858;
    background: var(--blue, #ffffff);
}
.header_table {
    display: grid;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    grid-template-columns: .1fr .1fr .1fr .1fr .1fr 1fr .5fr .5fr;
    grid-column-gap: 20px;
    border-top: 4px solid #C5C5C5;
    padding-top: 20px;
}

.header_table_sub {
    border-top: none;
}

.item {
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-weight: 276;
    line-height: 16px;
    letter-spacing: 0.045em;
    text-align: left;
    border-radius: 3px;
    background: var(--white, #FFF);
    padding: 8px 8px;
    height: 100%;
    position: relative;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 36px;
}

.item_action .item_title {
    text-align: center !important;
    color: #58A2E4 !important;
}

.item_title {
    overflow: hidden;
    /*height: 100%;*/
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--black, #1F1E1E);

    /* body 4 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
}

.item_title input {
    min-height: 100%;
    height: 39px;
    outline: none;
    border: none;
}

.header_title {
    position: absolute;
    top: -59px;
    /*left: 0px;*/
    color: #000;

    font-family: '.SF NS Rounded', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: left;
    white-space: nowrap;
    left: 0;
}

.item_title_city {
    color: #000;
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.9px;
}

.item_action {
    cursor: pointer;
    border-radius: 3px;
    border: 2px solid var(--blue, #58A2E4);
    background: var(--white, #FFF);
}
