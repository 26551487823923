.choose_page {
    background: #EAEAEA;
    margin: 18px 0;
    padding: 20px 0;
}

.navigate {
    width: 100%;
    display: grid;
    border-bottom: 1px solid rgba(65, 62, 62, .2);
    position: relative;
}

.navigate_item {
    align-items: center;
    color: #000;
    cursor: pointer;
    display: flex;
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    justify-content: center;
    letter-spacing: -.48px;
    line-height: normal;
    padding: 20px 24px;
}

.line {
    background: #1480c1;
    bottom: -1px;
    height: 2px;
    left: 0;
    position: absolute;
    transition: all .3s;
    width: 25%;
}

.content {
    background: #EAEAEA;
}

.empty_text {
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.45);
    padding-bottom: 50px;
    padding-top: 50px;
}
