.header {
    background: #EAEAEA;
    height: 85px;
    /*height:1vh;*/
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 80px;
    padding-right: 20px;
    position: sticky;
    top: 0;
    z-index: 5;
}

.header_split {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 50px;
}

.name {
    color: var(--black, #1F1E1E);
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.9px;
    margin-left: 22px;
}

.header_data {
    display: flex;
    align-items: center;
    text-align: center;
}

.wrappen_btns_reload {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.btn_reload {
    border: none;
    height: 40px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 5px;
    border-radius: 5px;
    background: var(--blue, #58A2E4);
    color: var(--white, #FFF);

    /* body 3 */
    text-align: center;
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    /*letter-spacing: 0.72px;*/
    cursor: pointer;
}

.btn_remove {
    border: none;
    height: 40px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 5px;
    border-radius: 5px;
    background: var(--blue, #d21b58);
    color: var(--white, #FFF);

    /* body 3 */
    text-align: center;
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    /*letter-spacing: 0.72px;*/
    cursor: pointer;
}

.btn_reload:hover {
    opacity: 0.7;
}

.btn_reload:active {
    background: black;
}

.btn_remove:hover {
    opacity: 0.7;
}

.btn_remove:active {
    background: black;
}

