.wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    align-items: flex-start;
    /*width: fit-content;*/
    width: 100%;
}

.content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /*grid-row-gap: 20px;*/
    grid-column-gap: 20px;
    width: 100%;
    /*grid-column-gap: 90px;*/
    /*align-items: center;*/
}

.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item_select_box {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 7px;
    width: 100%;
    margin-top: 20px;
}

.item_select_assistant {
}

.item_select_assistant  select {
    height: 32px;
    outline: none;
    border: none;
    padding: 0 16px;
    border-radius: 3px;
    width: 100%;
}

.item_select_box select {
    height: 32px;
    outline: none;
    border: none;
    padding: 0 16px;
    border-radius: 3px;
    width: 100%;
}
.item_select_box select option{
    /*padding-left: 10px;*/
}

.date_wrapper {
    margin-top: 40px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    grid-column: span 3;
}

.btn_wrapper {
    margin-left: 20px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.btn {
    padding: 0 24px;
    height: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #FFF;
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
    border-radius: 3px;
    background: #58A2E4;
    cursor: pointer;
    transition: .3s all;
    width: 100%;
}

.btn2 {
    margin-top: 20px;
}

.btn:hover {
    background: rgba(88, 162, 228, 0.61);
}

.coach_type_name {
    border-radius: 3px;
    background: var(--white, #FFF);
    padding: 0 16px;
    display: flex;
    align-items: center;
    height: 32px;
    width: 100%;
    margin-right: 7px;
}

.input {
    outline: none;
    border: none;
    padding: 0 10px;
    height: 32px;
    display: flex;
    align-items: center;
    width: 40px;
    color: var(--black, #1F1E1E);
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
}

.input::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}
