.content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 70px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header p {
    margin-right: 40%;
    color: #000;
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.9px;
}

.btn_icon {
    border-radius: 3px;
/*    border: 1px solid var(--blue, #58A2E4);*/
    background: var(--white, #FFF);
    height: 30px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.btn_icon svg {
    background: red;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    fill: #fff;
}

.item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.content_left_items {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    margin-top: 20px;
    max-height: 57vh;
    max-height: 57dvh;
    padding-right: 10px;
    overflow: auto;
    /*border: 1px solid red;*/
    /*max-height: 100%;*/
}

.pdf_viewer {
    min-height: 57vh;
    min-height: 57dvh;
    /*border: 1px solid red;*/
}

.content_left_items::-webkit-scrollbar-thumb:vertical {
    background: #EAEAEA;
    border-radius: 5px;
    border: 0.5px solid #1F1E1E;
}

.content_left_items::-webkit-scrollbar {
    height: 7px;
    width: 18px;
    border-radius: 5px;
    background: #fff;
}


.item_item {
    background: #FFFFFF;
    border-radius: 3px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 20px;
    min-width: 183px;
    /*width: 183px;*/
    /*max-width: 183px;*/
    /*padding: 10px 0;*/
    width: 100%;
    padding: 3px 0;
    padding-left: 10px;
}

.item_item select {
    border: none;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; /* Это скрывает стрелку на различных браузерах */
    text-align: center;
    /* Добавляем свойство, которое позволяет добавить пользовательский фоновый рисунок */
    /* Можно добавить свой собственный изображение, заменив 'none' на 'url('путь_к_изображению')' */
    background: none;
    background: #fff;
    height: 100%;
    width: 30px;
    font-family: '.SF NS Rounded', sans-serif;
    font-style: normal;
    font-weight: 508;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.045em;
    color: #1F1E1E;
}

.item_item_f {
    padding-left: 0;
    justify-content: center;
    min-width: 50px;
    height: 100%;
    max-width: 50px;
    width: fit-content;
    padding: 0;
    text-align: center;
}

.item_item_f select {
    height: 100%;
    width: 100%;
}

button[disabled] {
    pointer-events: none;
    opacity: 0.6;
}

.btn {
    border: none;
    padding: 10px 24px;
    border-radius: 5px;
    background: var(--blue, #58A2E4);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white, #FFF);
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    width: fit-content;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
    cursor: pointer;
}

.btn:hover {
    opacity: 0.7;
}

.btn:active {
    background: black;
}

.actions {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 25px;
}