.number_command_name {
    display: flex;
    align-items: center;
    min-width: 340px;
    width: 340px;
    max-width: 300px;
    height: 100%;
}

.item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    cursor: pointer;
    margin-top: 8px;
}

.number {
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    height: 100%;
    width: 30px;
    font-family: '.SF NS Rounded', sans-serif;
    font-style: normal;
    font-weight: 508;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.045em;
    color: #1F1E1E;
    border-radius: 3px;
}

.team_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    height: 100%;
    width: 50px;
    border-radius: 3px;
    margin-left: 10px;
}

.command_name {
    text-transform: uppercase;
    margin-left: 14px;
    background: #fff;
    height: 100%;
    width: 100%;
    padding-left: 16px;
    font-family: '.SF NS Rounded', sans-serif;
    font-style: normal;
    font-weight: 276;
    font-size: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.045em;
    color: #1F1E1E;
    border-radius: 3px;
}

.total_name, .elements_name, .rank_name, .artistik_name, .point_name {
    background: #FFFFFF;
    border-radius: 3px;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-left: 20px;
    min-width: 183px;
    width: 183px;
    max-width: 183px;
    padding-right: 20px;
}

.total_name {
    min-width: 138px;
    width: 138px;
    max-width: 138px;
}

.elements_name {
    min-width: 100px;
    width: 100px;
    max-width: 100px;
}

.point_name {
    min-width: 120px;
    width: 120px;
    max-width: 120px;
}

.rank_name {
    min-width: 100px;
    width: 100px;
    max-width: 100px;
}

.selected {
    background: #58A2E4;
    color: #fff;
}

.active_command {
    background-color: #86B760 !important;
    color: #fff !important;
}
