.left {
    background: var(--grey, #EAEAEA);
    padding: 20px 30px;
    display: grid;
    grid-row-gap: 10px;
    grid-template-columns: 1fr;
    padding-top: 50px;
}

.row {
    display: grid;
    grid-template-columns: 0.95fr .8fr;
    grid-column-gap: 10px;

}

.row_item {
    min-height: 32px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white, #FFF);
    padding: 8px 16px;
    position: relative;
    color: var(--black, #1F1E1E);

    /* body 4 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
}

/*.row_item_title_3 ,.row_item_title_2,.row_item_title_1{*/
/*    max-width: 100px;*/
/*    width: 100%;*/
/*}*/

.row_item_title_1::before {
    content: 'Участники';
    position: absolute;
    top: -25px;
    /*left: 0;*/
    color: #000;

    /* body 3 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
}

.row_item_title_2::before {
    content: 'Финал';
    position: absolute;
    top: -25px;
    /*left: 0;*/
    color: #000;

    /* body 3 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
}

.row_item_title_3::before {
    content: 'П';
    position: absolute;
    top: -25px;
    /*left: 0;*/
    color: #000;

    /* body 3 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
}

.row_content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 6px;
}

.btn_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    /*border: 1px solid red;*/
    background: var(--grey, #EAEAEA);
    padding: 20px 30px;
}

.btn {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 24px;
    border-radius: 3px;
    background: var(--blue, #58A2E4);
    color: var(--white, #FFF);

    /* body 3 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
    cursor: pointer;
}

.btn_save {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 5px;
    border-radius: 3px;
    background: var(--blue, #58A2E4);
    color: var(--white, #FFF);

    /* body 3 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    cursor: pointer;
}

.btn_save:disabled {
    background: grey;
}

.en {
    color: #86B760;
    font-size: 14px;
    font-weight: 700;
}

.dis {
    color: #E9695E;
    font-size: 16px;
    font-weight: 700;

}

.input {
    max-width: 110px;
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
    text-align: center;
    min-height: 32px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white, #FFF);
    padding: 0;
    position: relative;
    color: var(--black, #1F1E1E);

    /* body 4 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;

}

.input[type="checkbox"] {
    max-width: 110px;
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
    text-align: center;
    min-height: 32px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white, #FFF);
    padding: 0;
    position: relative;
    color: var(--black, #1F1E1E);

    /* body 4 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;

}

.input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.title {
    color: var(--black, #1F1E1E);
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.9px;
    width: 100%;
    background: var(--grey, #EAEAEA);
    padding: 20px 30px;
}

.test {
    background: var(--grey, #EAEAEA);
}
