.input {
    outline: none;
    border: none;
    padding: 0 10px;
    height: 32px;
    display: flex;
    align-items: center;
    width: 200px;
    color: var(--black, #1F1E1E);
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
    margin-bottom: 20px;
}

.content {
    background: #eaeaea;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.select_box {
    min-width: 200px;
    outline: none;
    border: none;
    padding: 0 10px;
    height: 32px;
    color: var(--black, #1F1E1E);
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
}

.button {
    margin-top: 30px;
    padding: 0 24px;
    height: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #FFF;
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
    border-radius: 3px;
    background: #58A2E4;
    cursor: pointer;
    transition: .3s all;
    width: 200px;
}

.date_wrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    grid-column: span 3;
}