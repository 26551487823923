.content {
    padding: 25px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content_center {
    display: flex;
    align-items: center;
}

.content_center_download {
    margin-left: 38px;
    margin-right: 58px;
    border-radius: 3px;
    border: 2px solid var(--blue, #58A2E4);
    background: var(--white, #FFF);
    height: 39px;
    padding: 0 24px;
    color: var(--blue, #58A2E4);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
    cursor: pointer;
}

.content_center_random {
    border-radius: 3px;
    background: var(--white, #58A2E4);
    height: 39px;
    padding: 0 24px;
    color: var(--blue, #FFF);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
    cursor: pointer;
}

.content_center_number {
    border-radius: 3px;
    background: var(--white, #fff);
    height: 39px;
    padding: 0 24px;
    color: var(--blue, #1F1E1E);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
}

.input::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.input {
    height: 39px;
    padding: 10px;
    color: var(--black, #1F1E1E);
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
    outline: none;
    border: none;
    width: 50px;
    border-radius: 3px;
    margin-right: 15px;
}

.content_end {
    display: flex;
}
