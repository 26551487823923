.right {
    background: var(--grey, #EAEAEA);
    padding: 20px 30px;
}


.title {
    color: var(--black, #1F1E1E);
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.9px;
    width: 100%;
    background: var(--grey, #EAEAEA);
    padding: 0px 0px 20px 0;
}

.static_res, .static_res_main, .static_res_sub_main {
    display: flex;
    align-items: center;
}

.static_res {
    flex-direction: column;
    align-items: flex-start;
}

.static_res_main {
    margin-top: 15px;
}

.item_name, .static_res_sub_main_item {
    display: flex;
    align-items: center;
    border-radius: 3px;
    background: var(--white, #FFF);
    padding: 8px 16px;
    color: var(--black, #1F1E1E);
    min-height: 32px;
    /* body 4 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
}

.item_name {
    max-width: 285px;
    min-width: 285px;
    width: 100%;
    margin-right: 30px;
}

.btn_save {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 5px;
    margin-right: 20px;
    border-radius: 3px;
    background: var(--blue, #58A2E4);
    color: var(--white, #FFF);

    /* body 3 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    cursor: pointer;
}

.btn_save:disabled {
    background: grey;
}


.plus {
    margin: 0 15px;
    color: var(--black, #1F1E1E);
    text-align: center;

    /* body 4 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
}

.sub_title {
    color: #000;

    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
    margin-top: 10px;
    margin-bottom: 8px;
}

.added_box {
    display: flex;
    align-items: center;
    height: 32px;
    margin-top: 65px;
}

.added_box input {
    outline: none;
    border: none;
    height: 100%;
    max-width: 175px;
    width: 100%;
    padding: 8px 16px;
    color: var(--black, #1F1E1E);
    border-radius: 3px;
    background: var(--white, #FFF);
    /* body 4 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
}


.add {
    height: 100%;
    width: 32px;
    margin-left: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: 2px solid var(--blue, #58A2E4);
    background: var(--white, #FFF);
    color: var(--blue, #58A2E4);

    /* body 3 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
    cursor: pointer;
}

.dinamic_items {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
    margin-top: 50px;
}

.row {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 14px;
}

.row_top, .row_bottom {
    display: grid;
    grid-template-columns: .5fr .8fr;
    grid-column-gap: 35px;
}

.title_row {
    color: #000;
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
}

.name_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 32px;
    min-height: 32px;
}

.name_box_name {
    border-radius: 3px;
    background: var(--white, #FFF);
    min-height: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    height: 100%;
}

.action {
    width: 32px;
    min-width: 32px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background: var(--blue, #58A2E4);
    color: var(--white, #FFF);
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
    cursor: pointer;
}

.chosing_items_item {
    display: flex;
    align-items: center;
    min-height: 32px;
    max-height: 32px;
    position: relative;

}

.chosing_items_item_plus::after {
    content: '+';
    position: absolute;
    right: -14px;
    color: var(--black, #1F1E1E);
    text-align: center;

    /* body 4 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
}

.chosing_items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 20px;
}

.chosing_items_item_title {
    border-radius: 3px;
    background: var(--white, #FFF);
    display: flex;
    /*width: 58px;*/
    width: 100%;
    /*max-width: 60px;*/
    /*min-width: 60px;*/
    padding: 8px 16px;
    align-items: center;
    gap: 10px;
    color: var(--black, #1F1E1E);
    height: 100%;
    /* body 4 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
}

.input {
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    padding-left: 5px;
    color: var(--black, #1F1E1E);

    /* body 4 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
}
