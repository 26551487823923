.header {
    background: #EAEAEA;
    height: 85px;
    /*height:1vh;*/
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 80px;
    padding-right: 80px;
    /*padding-left: 80px;*/
    position: sticky;
    top: 0;
    z-index: 5;
}

.header_data {
    display: flex;
    align-items: center;
}

.name {
    color: var(--black, #1F1E1E);
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.9px;
}
