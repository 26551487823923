.item {
    /*padding: 0 10px;*/
    padding: 0 5px;
    background: #FFFFFF;
    border-radius: 3px;
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    outline: none;
    border: none;
}

.item::-webkit-outer-spin-button,
.item::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.close_before::before {
    content: '';
    width: 5px;
    background-color: #fff;
    /*border: 1px solid red;*/
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
}

.edit {
    background: rgba(128, 128, 128, 0.15);
    cursor: pointer;
}

.preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.preloader span {
    height: 25px !important;
    width: 25px !important;
}

.input {
    outline: none;
}

.disabled {
    cursor: not-allowed !important;
    background-color: rgba(168, 168, 168, 0.66) !important;
}
