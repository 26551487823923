.listCoach {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
}

.header_coach {
    padding: 0 10px;
}

.device_number {
    padding: 5px;
    font-size: 18px;
    width: 50px;
    border: 1px solid black;
    margin: 5px;
    text-align: center;
}