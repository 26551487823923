.content {
    background: #EAEAEA;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    padding: 30px 90px 20px 90px;
    flex-direction: column;
    max-width: 1700px;
    margin: 0 auto;
}

.warpper {
    margin-top: 8px;
    background: #EAEAEA;
    width: 100%;
    min-height: calc(100dvh - 102px);
}

.table {
    /*border: 1px solid red;*/
    width: 100%;
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 7px;
    padding-top: 30px;
}

.item {
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-weight: 276;
    line-height: 16px;
    letter-spacing: 0.045em;
    text-align: left;
    border-radius: 3px;
    background: var(--white, #FFF);
    padding: 8px 16px;
    height: 100%;
    position: relative;
}

.item_title {
    overflow: hidden;
    /*height: 100%;*/
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.header {
    display: grid;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
    grid-template-columns:.3fr 1fr .4fr .4fr .6fr .5fr .5fr .5fr .5fr .5fr .5fr .4fr;
    grid-column-gap: 7px;
}

.header_title {
    position: absolute;
    top: -30px;
    /*left: 0px;*/
    font-family: '.SF NS Rounded', sans-serif;
    font-style: normal;
    font-weight: 276;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.045em;
    color: #1F1E1E;
    text-align: left;
    white-space: nowrap;
}

.search {
    display: flex;
    align-items: center;
    /*border: 1px solid;*/
    width: 100%;
    height: 45px;
    margin-bottom: 16px;
}

.input {
    height: 100%;
    padding: 0 25px;
    outline: none;
    width: 258px;
    border-radius: 3px;
    border: 1px solid var(--blue, #58A2E4);
    background: var(--white, #FFF);

    color: var(--black, #1F1E1E);

    /* body 3 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
}

.btn {
    margin-left: 12px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    color: var(--blue, #58A2E4);

    /* body 3 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
    border-radius: 3px;
    border: 2px solid var(--blue, #58A2E4);
    background: var(--white, #FFF);
    cursor: pointer;
}

.btn_calc {
    margin-left: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    color: var(--blue, #58A2E4);

    /* body 3 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
    border-radius: 3px;
    border: 2px solid var(--blue, #58A2E4);
    background: var(--white, #FFF);
    cursor: pointer;
}

.navigate{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.switch{
    display: flex;
    align-items: center;
}

.switch p {
    right: 10px;

    /*left: 0px;*/
    font-family: '.SF NS Rounded', sans-serif;
    font-style: normal;
    font-weight: 276;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.045em;
    color: #1F1E1E;
    text-align: left;
    white-space: nowrap;
}
