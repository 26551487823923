.header {
    background: #EAEAEA;
    height: 85px;
    /*height:1vh;*/
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 80px;
    padding-right: 20px;
    position: sticky;
    top: 0;
    z-index: 6;
}

.versions {
    display: flex;
    padding-right: 10px;
    justify-content: end;
    margin-top: auto;
}

.version {
    font-size: 12px;
    font-weight: 900;
    letter-spacing: -0.08em;
    color: #9f9c9c;
    cursor: pointer;
}

.header_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.choose_select_value {
    min-width: 80px;
    padding: 8px 10px;
    background: #fff;
    font-family: '.SF NS Rounded', sans-serif;
    font-style: normal;
    font-weight: 276;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.045em;
    color: #000000;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
}

.header_middle_box {
    display: flex;
}

.choose_select_value {
    margin-right: 22px;
}

.btns {
    margin-left: 46px;
}
