.sportsmen_item {
    display: flex;
    align-items: center;
    height: 35px;
    min-height: 35px;
    max-height: 35px;
    margin: 15px auto 0 auto;
}

.sportsmen_item div {
    display: flex;
    align-items: center;
    padding-left: 16px;
    height: 100%;
    background-color: #EAEAEA;
    border-radius: 3px;
    font-family: '.SF NS Rounded', sans-serif;
    font-style: normal;
    font-weight: 276;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.045em;
    color: #1F1E1E;
}

.sportsmen_item_1 {
    width: 380px;
    max-width: 380px;
    min-width: 380px;
}

.sportsmen_item_2 {
    width: 180px;
    max-width: 180px;
    min-width: 180px;
    margin-left: 10px;
}
