.judging_wrapper {
    position: relative;
}

.select {
    position: fixed;
    height: 50px;
    width: 50px;
    border-radius: 3px;
    border: 2px solid var(--blue, #58A2E4);
    background: var(--white, #FFF);
    top: 20px;
    left: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 10px;
    flex-direction: column;
    cursor: pointer;
    z-index: 10;
}

.select_line {
    width: 100%;
    height: 2px;
    background: #000;
}

.select_menu {
    min-width: 100px;
    overflow: hidden;
    position: absolute;
    background: #fff;
    border-radius: 10px;
    top: 50px;
    left: 0;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.select_menu_item {
    color: var(--black, #1F1E1E);
    white-space: nowrap;
    padding: 14px 25px;
    cursor: pointer;
    transition: .3s all;
    /* body 3 */
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
}

.select_menu_item:hover {
    background: rgba(211, 211, 211, 0.2);
}

.selected_item {
    background: rgba(211, 211, 211, 0.3);
}

.selected_item:hover {
    background: rgba(211, 211, 211, 0.3);
}
