.content_top {
    display: grid;
    grid-template-columns: .7fr 1fr .7fr;
    grid-column-gap: 20px;
}

.content_bottom {
    display: grid;
    grid-template-columns: 1.7fr .7fr;
    grid-column-gap: 20px;
}

.item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 200px;
    width: 100%;
    flex-direction: column;
}

.item_l {
    justify-content: flex-start;
    align-items: flex-start;
}

.close_box {
    position: relative;
}

.close_icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    border-radius: 50%;
    background: #f44336;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.close_icon svg {
    fill: #fff;

}

.title {
    color: var(--black, #1F1E1E);
    font-family: '.SF NS Rounded', sans-serif;
    margin-bottom: 12px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.9px;
    text-align: left;
    width: 100%;
}

.comment {
    color: #000;
    font-family: '.SF NS Rounded', sans-serif;
    margin-top: 22px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.81px;
}

.line {
    height: 4px;
    width: 100%;
    background: #C5C5C5;
    margin: 50px 0;
}

.input_box, .input_box input {
    width: 100%;
}

.input_box input {
    border-radius: 3px;
    border: 1px solid var(--blue, #58A2E4);
    background: var(--white, #FFF);
    padding: 14px 16px 14px 25px;
    color: var(--black, #1F1E1E);
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.72px;
    margin-top: 11px;
}

.input_title {
    color: #000;
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.81px;
}

.item_input {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
}

.btn_save_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 25px;
}

.btn_save {
    max-width: 130px;
    border-radius: 3px;
    background: var(--blue, #58A2E4);
    width: 100%;
    padding: 10px 0;
    color: var(--white, #FFF);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* body 3 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
}