.content {
    display: grid;
    grid-template-columns: 1fr 0.8fr;
    grid-column-gap: 13px;
    margin-top: 10px;
    min-height: 600px;
}

.empty_text {
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.45);
    padding-bottom: 50px;
    padding-top: 50px;
}
