.item {
    margin-top: 6px;
    display: flex;
    width: 100%;
    height: 32px;
    position: relative;
}

.item_number {
    position: absolute;
    left: -70px;
    text-align: right;
    /*top: 5px;*/
    /*border: 1px solid red;*/
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /*border: 1px solid red;*/
    width: 60px;
    font-family: '.SF NS Rounded', sans-serif;
    font-style: normal;
    font-weight: 508;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.045em;
    color: #1F1E1E;
}

.elements_box {
    /*border: 1px solid red;*/
    height: 32px;
    display: flex;
    min-width: 400px;
    width: 400px;
    max-width: 400px;
    /*border: 1px solid;*/
}

.table_item_1 {
    height: 100%;
    background: #fff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    width: 80px;
    max-width: 80px;
    min-width: 80px;
    border-radius: 3px;
}

.process_digit {
    color: rgb(89 162 229);
    /*color: rgba(100, 103, 101, 0.75);*/
    /*font-weight: 100;*/
}

.main_digit {
    color: black;
}

.green {
    background: #86B760 !important;
}

.yellow {
    background: #EBC810 !important;
}

.red {
    background: #E9695E !important;
}

.green__process {
    border: 3px solid #86B760 !important;
    background: white !important;
}

.yellow__process {
    border: 3px solid #EBC810!important;
    background: white !important;
}

.red__process{
    border: 3px solid #E9695E !important;
    background: white !important;
}

.colored_picker {
    cursor: pointer !important;
}

.colored_picker::before {
    content: none !important;
}
