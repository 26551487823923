.App {
    display: flex;
    flex-direction: column;
    background: #fff;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    padding: 0 8px;
}

#test .MuiPaper-root {
    /*max-width: 900px !important;*/
    /*!*border: 1px solid red !important;*!*/
    /*width: 100% !important;*/
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.045em;
    text-align: left;
}

.action2 {
    max-width: 80%;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    margin-top: 45px;
}

.btn_box {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.btn {
    padding: 10px 24px;
    color: var(--white, #FFF);
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.72px;
    border-radius: 3px;
    background: var(--blue, #58A2E4);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s all;
}

.btn:hover {
    background: var(--blue, rgba(88, 162, 228, 0.7));
}

.textaera2 {
    width: 100%;
    resize: none;
    padding: 14px 27px;
    /*min-height: 163px;*/
    margin-top: 15px;
    border: none;
    background: #fff;
}

.textaera2::placeholder {
    color: var(--black, #1F1E1E);

    /* body 2 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.9px;
}
