.table {
    background: #EAEAEA;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 8px;
    width: 100%;
    padding-top: 18px;
    padding-bottom: 20px;
    min-height: 100%;
}

.process_digit {
    color: rgb(89 162 229);
    /*color: rgba(21, 187, 171, 0.60);*/
    /*color: rgba(100, 103, 101, 0.75);*/
    /*font-weight: 100;*/
}

.main_digit {
    color: black;
}

.table_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.title {
    font-family: '.SF NS Rounded', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.045em;
    color: #1F1E1E;
}

.disconnect {
    color: red;
    animation: colored 1s infinite linear;
    cursor: pointer;
}

.connect {
    color: #4c4cf6 !important;
    cursor: pointer;
}

@keyframes colored {
    0% {
        color: rgba(255, 0, 0, 1);
    }
    10% {
        color: rgba(255, 0, 0, .90);
    }
    20% {
        color: rgba(255, 0, 0, .80);
    }
    30% {
        color: rgba(255, 0, 0, .70);
    }
    40% {
        color: rgba(255, 0, 0, .60);
    }
    50% {
        color: rgba(255, 0, 0, 0);
    }
    60% {
        color: rgba(255, 0, 0, .60);
    }
    70% {
        color: rgba(255, 0, 0, .70);
    }
    80% {
        color: rgba(255, 0, 0, .80);
    }
    90% {
        color: rgba(255, 0, 0, .90);
    }
    100% {
        color: rgba(255, 0, 0, 1);
    }
}

.empty {
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.45);
}

.table_box {
    margin-top: 24px;
    display: flex;
    width: 100%;
}

.main_table_right {
    /*margin-left: -30px;*/
}

.main_table_right h3 {
    display: flex;
    font-family: '.SF NS Rounded', sans-serif;
    font-style: normal;
    font-weight: 276;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.045em;
    color: #1F1E1E;
    height: 32px;
    align-items: center;
    margin-left: 15px;
    margin-top: 3px;
}

.main_tible_right_item {
    margin-top: 6px;
    height: 32px;
    display: flex;
}

.main_table_score {
    margin-top: 38px;
    margin-left: 15px;
}

.clear_score {
    margin-top: 38px;
    margin-left: 20px;
    color: #3498db;
}

.clear_score:hover {
    cursor: pointer;
    text-decoration: underline;
}

.main_table_score h3 {
    font-family: '.SF NS Rounded', sans-serif;
    font-style: normal;
    font-weight: 276;
    font-size: 20px;
    line-height: 24px;
    margin-left: 0;
    letter-spacing: 0.045em;
    color: #1F1E1E;
}

.main_table_score_sub {
    margin-top: 0;
    margin-bottom: -5px;
}

.main_table_score_sub h3 {
    color: var(--black, #1F1E1E);

    /* body 4 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
}

.main_table_score_score {
    width: 134px;
    height: 44px;
    background: #FFFFFF;
    border: 2px solid #58A2E4;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: '.SF NS Rounded', sans-serif;
    font-style: normal;
    font-weight: 276;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.045em;
    color: #1F1E1E;
}

.main_table_score_score_sub {
    color: #000;

    /* body 4 */
    font-family: '.SF NS Rounded', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 276;
    line-height: normal;
    letter-spacing: 0.63px;
    text-align: left;
    justify-content: flex-start;
    padding-left: 10px;
    width: 134px;
    height: 32px;
}

.table_title {
    display: flex;
    align-items: center;
}

.table_title h3 {
    display: flex;
    font-family: '.SF NS Rounded', sans-serif;
    font-style: normal;
    font-weight: 276;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.045em;
    color: #1F1E1E;
}

.table_title_1 {
    min-width: 400px;
    width: 400px;
    max-width: 400px;
}

.table_title_2, .table_title_3, .table_title_4 {
    margin-left: 10px;
    width: 36px;
    min-width: 36px;
    max-width: 36px;
}

.table_title_5 {
    margin-left: 15px;
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    margin-right: 5px;
}

.table_title_6, .table_title_7, .table_title_8, .table_title_9, .table_title_10 {
    margin-left: 10px;
    min-width: 50px;
    max-width: 50px;
    width: 50px;
}

.table_title_11 {
    min-width: 90px;
    max-width: 90px;
    width: 90px;
    margin-left: 5px;
    background: #fff;
    font-family: '.SF NS Rounded', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.045em;
    color: #1F1E1E;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 5px;
    height: 32px;
}

.table_items {
    margin-top: 9px;
    /*border: 1px solid red;*/
}

.table_bottom {
    display: flex;
    margin-top: 30px;
}

.table_bottom_left_title {
    display: flex;
    align-items: center;
}

.table_bottom_left_title h3 {
    font-family: '.SF NS Rounded', sans-serif;
    font-style: normal;
    font-weight: 276;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.045em;
    color: #1F1E1E;
}

.table_bottom_left_title_1 {
    font-size: 20px !important;
    line-height: 24px !important;
    width: 175px;
    min-width: 175px;
    max-width: 175px;
}

.table_bottom_left_title_2,
.table_bottom_left_title_3,
.table_bottom_left_title_4 {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    margin-left: 10px;
}

.table_bottom_left_content_item {
    height: 32px;
    display: flex;
    margin-top: 6px;
}

.table_bottom_right {
    margin-left: 16px;
}

.table_bottom_right_title_1 {
    max-width: 143px;
    min-width: 143px;
    width: 143px;
}
